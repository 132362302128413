import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./TimeSlots.module.css";
import parse from "html-react-parser";
import { withTranslation } from "react-i18next";
import ScrollNext from "../../../ReusableComponents/ScrollNext/ScrollNext";
import ScrollPrevious from "../../../ReusableComponents/ScrollPrevious/ScrollPrevious";

const TimeSlots = ({ ...props }) => {
  const { t } = props;

  const [timeSlots, setTimeSlots] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedDate, setSelectedDate] = useState();

  let timeArr = [];

  useEffect(() => {
    setTimeSlots(props.times);
    setSelectedDate(props.dateSelected);
  }, [props.times]);

  const getStyles = (slot) => {
    if (slot === selectedSlot) {
      return true;
    }
    return null;
  };

  const slotSelection = (slot) => {
    setSelectedSlot(slot);
    props.selectedTime(slot);
  };

  const scrollNextWeek = () => {
    const e = document.getElementById("scroll-time");
    const width = e.getBoundingClientRect().width;
    e.scrollLeft += width - 0;
  };

  const scrollPrevWeek = () => {
    const e = document.getElementById("scroll-time");
    const width = e.getBoundingClientRect().width;
    e.scrollLeft -= width - 0;
  };

  const convertIntoLocalTime = (timeSlot) => {
    var t = new Date(selectedDate);
    var t_split = timeSlot.timestamp.split(":");
    //year, month, day, hours, minutes, seconds, millisec
    var date = new Date(
      Date.UTC(
        t.getFullYear(),
        t.getMonth(),
        t.getDate(),
        t_split[0],
        t_split[1],
        0
      )
    );
    var options = { hour12: false, hour: "2-digit", minute: "2-digit" };
    var time = date.toLocaleTimeString([], options);
    return time;
  };

  function setTiming() {
    if (timeSlots)
      for (let i = 0; i < timeSlots.length; i++) {
        var time = convertIntoLocalTime(timeSlots[i]);
        timeArr.push(
          <div
            key={i}
            className={`${styles.timeWrapper} bookingTimeWrapperCompanyStyles ${
              getStyles(timeSlots[i])
                ? `${styles.selectedStyle} timeSelectedCompanyStyle`
                : null
            }`}
            onClick={() => slotSelection(timeSlots[i])}
          >
            {time}
          </div>
        );
      }
    return (
      <div className={styles.timeSlotsContainer}>
        {/* <div className={styles.buttonWrapper}>
          <img src={leftArrow} alt="arrow" onClick={scrollPrevWeek}></img>
        </div> */}
        <ScrollPrevious onClick={scrollPrevWeek}></ScrollPrevious>
        <div id="scroll-time" className={styles.timeSlotsWrapper}>
          {timeArr.length > 0 ? timeArr : parse(t("No slots svailable"))}
        </div>
        {/* <div className={styles.buttonWrapper}>
          <img src={rightArrow} alt="arrow" onClick={scrollNextWeek}></img>
        </div> */}
        <ScrollNext onClick={scrollNextWeek}></ScrollNext>
      </div>
    );
  }

  if (props.times && timeSlots) {
    return setTiming();
  } else {
    return <p>{parse(t("No slots svailable"))}</p>;
  }
};

export default withTranslation()(TimeSlots);
