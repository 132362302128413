import styles from "./NewHeader.module.css";
import React, { useEffect } from "react";
import clock from "../../../assets/images/clock.svg";
import cam from "../../../assets/images/cam.svg";
import arrow from "../../../assets/images/arrow_blue.svg";
import { fetchUpcomingAppointments } from "../../../api/index";
import { useState } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import leftArrow from "../../../assets/images/close_black.png";
import "moment/locale/nb";
import Navbar from "../../Navbar/Navbar";
import mood from "../../../assets/images/dashboard_mood.png";
import moodMobile from "../../../assets/images/dashboard_mobile_mood.svg";

const NewHeader = ({ ...props }) => {
  const { t } = props;
  const [appointments, setAppointments] = useState();
  const [viewAll, setViewAll] = useState("none");
  const [opa, setOpa] = useState("hello");

  useEffect(() => {
    const getAppointment = async () => {
      const response = await fetchUpcomingAppointments();
      setAppointments(response.bookings);
      props.checkIsFirstAppointment(!response.isOldBooking);
    };
    getAppointment();
    //setOpa("1");
  }, []);

  const getDate = (timestamp) => {
    const day = moment(timestamp).calendar({
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[Last] dddd",
      nextWeek: "[Next] dddd",
      sameElse: "L",
    });

    const no_date_from = moment(timestamp).locale("en"); //norwegian date
    //console.log(no_date_from);
    // if (day == "Today" || day == "Tomorrow") {
    //   return day + moment(timestamp).format(", Do MMM YYYY");
    // }
    return no_date_from.format("Do MMM HH:mm  ");
  };

  const clickHandler = () => {
    //Expand Panel
    setViewAll("flex");
    document.querySelector("#header").style.filter = "blur(3px)";
    document.querySelector("#cards").style.filter = "blur(3px)";
  };
  const clickHandlerLess = () => {
    console.log("calling");
    //Squeeze Panel
    setViewAll("none");
    document.querySelector("#header").style.filter = "blur(0px)";
    document.querySelector("#cards").style.filter = "blur(0px)";
    //setViewLess("flex")
  };

  const appointmentBlock = (toDisplay) => {
    //If only two appointments
    let app = [];
    for (let i = 0; i < appointments.length; i++) {
      let v = getDate(appointments[i].booked_from);
      let confrere_url = appointments[i].resource_id.confrere_url;
      let firstChar = appointments[i].resource_id.name.charAt(0);
      if (toDisplay === "onlyTwo" && i === 2) break;
      app.push(
        <div className={styles.appointmentWrapper}>
          <div className={styles.appointmentDetails}>
            <img
              src={
                appointments[i].resource_id.image == null
                  ? `https://moodahead.s3.eu-central-1.amazonaws.com/moodahead-public/alphabetic-placeholders/${firstChar}.png`
                  : appointments[i].resource_id.image
              }
              alt="doctor"
              className={styles.doctorProfileImage}
            ></img>
            <div className={styles.appointmentTextWrapper}>
              <p className={styles.doctorName}>
                {"Expert: "}
                {appointments[i].resource_id.name}
              </p>
              <p className={styles.doctorNameMobile}>
                {appointments[i].service_id.title}
              </p>
              <div className={styles.time}>
                <img src={clock} alt="clock" className={styles.callImg}></img>
                <p className={styles.appTime}>{v}</p>
              </div>
            </div>
          </div>
          <a
            href={appointments[i].booking_url}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.callIconWrapper}>
              <img
                src={cam}
                alt="doctor"
                className={styles.docExtraImage}
              ></img>
            </div>
          </a>
        </div>
      );
    }

    return app;
  };

  const appointmentBlockAll = (toDisplay) => {
    //if more than two appointments
    let app = [];

    for (let i = 0; i < appointments.length; i++) {
      let v = getDate(appointments[i].booked_from);
      let firstChar = appointments[i].resource_id.name.charAt(0);
      if (toDisplay === "onlyTwo" && i === 2) break;
      app.push(
        <div className={styles.expandAppointmentWrapper}>
          <img
            src={
              appointments[i].resource_id.image == null
                ? `https://moodahead.s3.eu-central-1.amazonaws.com/moodahead-public/alphabetic-placeholders/${firstChar}.png`
                : appointments[i].resource_id.image
            }
            alt="doctor"
            className={styles.expandDoctorImage}
          ></img>
          <div className={styles.appointmentTextWrapper}>
            <p className={styles.doctorName}>
              {"Expert: "}
              {appointments[i].resource_id.name}
            </p>
            <p className={styles.doctorNameMobile}>
              {appointments[i].service_id.title}
            </p>
            <div className={styles.time}>
              <img src={clock} alt="clock" className={styles.callImg}></img>
              <p className={styles.appTime}>{v}</p>
            </div>
          </div>
          <a
            href={appointments[i].booking_url}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.expandCallIcon}>
              {" "}
              <img
                src={cam}
                className={styles.docExtraImage}
                alt="doctor"
              ></img>
            </div>
          </a>
        </div>
      );
    }

    return app;
  };
  return (
    <>
      <div id="header" className={styles.mainContainer}>
        <Navbar hasBackground={true}></Navbar>
        <div className={styles.imgContainer}>
          <img className={styles.mood} src={mood} alt="mood"></img>
          <img className={styles.moodMobile} src={moodMobile} alt="mood"></img>
          <div className={styles.upcomingAppointmentsWrapper}>
            {/* <div
              id="appointment1"
              className={styles.docDiv}
              styles={{ display: viewLess }}
            > */}
            <p className={styles.notifications}>{parse(t("Notifications"))}</p>

            {appointments && appointments.length > 0 ? (
              appointmentBlock("onlyTwo")
            ) : (
              <div className={styles.notifyDiv}>
                <p>{parse(t("There are no upcoming appointments"))}</p>
              </div>
            )}
            {appointments && appointments.length > 2 && (
              <div
                className={styles.viewAll}
                style={{
                  opacity:
                    appointments && appointments.length > 0 ? "1" : "0.4",
                  pointerEvents:
                    appointments && appointments.length > 0 ? "auto" : "none",
                }}
              >
                <p onClick={() => clickHandler()}>{parse(t("View All"))}</p>
                <img src={arrow} className={styles.btnArrow} alt="arrow"></img>
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
      {appointments && (
        <div
          id="expand"
          className={styles.displayAll}
          style={{ display: viewAll, opacity: opa }}
        >
          <div className={styles.backBtnWrapper}>
            <p className={styles.notifications}>
              {parse(t("All Notifications"))}
            </p>
            {/* <button onClick={() => clickHandlerLess()}>
              {parse(t("View All"))}
            </button> */}
            <img
              onClick={() => clickHandlerLess()}
              className={styles.btnArrow}
              alt="arrow"
              src={leftArrow}
            ></img>
          </div>
          {/* <a href="#" onClick={() => clickHandlerLess()} style={{ textDecoration: 'none', color: '', marginLeft: 'px', }}> <span><i className="fas fa-chevron-left" style={{ marginRight: '10px' }}></i>Back</span></a> */}

          {appointments ? (
            appointmentBlockAll("all")
          ) : (
            <p>{parse(t("Loading..."))}</p>
          )}
        </div>
      )}
    </>
  );
};

export default withTranslation()(NewHeader);
