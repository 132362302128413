import React from "react";

import styles from "./ScrollPrevious.module.css";
import leftArrow from "../../assets/images/chevron-left.svg";

const ScrollPrevious = ({ ...props }) => {
  return (
    <div
      className={`${styles.scrollNextContainer} ${props.className}`}
      onClick={() => props.onClick()}
    >
      <img src={leftArrow} className={styles.chevronArrow} alt="arrow"></img>
    </div>
  );
};

export default ScrollPrevious;
