import React, { useState, useEffect } from "react";
import styles from "./OnboardUser.module.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LeftPanel from "../NewLogin/LeftPanel/LeftPanel";
import OtpInput from "react-otp-input";
import { setPhonenumber, verifyOtp, setUserPassword } from "../../api";
import { useRouteMatch } from "react-router-dom";
import Modal from "react-modal";
import close from '../../assets/images/close.svg';
import modal_check from '../../assets/images/modal_check.svg';
import alertCircle from '../../assets/images/alert-circle1.svg';
import PhoneInput from "react-phone-input-2";
import logo from '../../assets/images/logo.svg';
import eyeOff from '../../assets/images/eye-off.svg';
import eyeOn from '../../assets/images/eye-on.svg';
import loader from '../../assets/images/loader.gif';

const OnboardUser = (props) => {
    const match = useRouteMatch();
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(1);
    const [phonenumber, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpHash, setOtpHash] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [apiCallComplete, setApiCallComplete] = useState(true);
    const { t } = props;

    useEffect(() => {
        setEmail(match.params.email);
        setPhone(match.params.phone);
    }, []);

    const setPhoneNum = async (e) => {
        e.preventDefault();
        setShowError(false);
        setApiCallComplete(false);
        try {
            const res = await setPhonenumber(phonenumber, email);
            if (res.otpHash) {
                setOtpHash(res.otpHash);
                setStep(2);
            }
        } catch (error) {
            setError(error.response.data.error);
            setShowError(true);
        } finally {
            setApiCallComplete(true);
        }
    };

    const verOtp = async (e) => {
        e.preventDefault();
        setShowError(false);
        setApiCallComplete(false);
         try {
            const res = await verifyOtp(phonenumber, otpHash, otp);
            if (res) {
                setStep(3);
            }
        } catch (error) {
            setError(error.response.data.error);
            setShowError(true);
        } finally {
            setApiCallComplete(true);
        }
    };

    const setPass = async (e) => {
        e.preventDefault();
        setShowError(false);
        setApiCallComplete(false);
         try {
            const res = await setUserPassword(phonenumber, otpHash, password);
            if (res) {
                setShowModal(true);
            }
        } catch (error) {
            setError(error.response.data.error);
            setShowError(true);
        } finally {
            setApiCallComplete(true);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        props.history.push("/login");
    };

  return (
      <div className={styles.mainContainer}>
          <LeftPanel></LeftPanel>
          <div className={styles.rightContainer}>
              <div className={styles.outerContainer}>
              <img className={styles.logoImg} alt="logo" src={logo}></img>
                  <div className={styles.title}>{t("onboard_title")}</div>
                  <div className={styles.stepper}>
                      <div className={step >= 1 ? styles.stepperActive : styles.stepperInactive}></div>
                      <div className={step >= 2 ? styles.stepperActive : styles.stepperInactive}></div>
                      <div className={step >= 3 ? styles.stepperActive : styles.stepperInactive}></div>
                  </div>
                    { (step === 1) &&
                      <div className={styles.innerContainer}>
                          <form onSubmit={setPhoneNum}>
                            <label className={styles.subtitle}>{t("onboard_subtitle")}</label>
                              <label className={styles.email}>{email}</label>
                               { showError && <div className={styles.errorDiv}>
                                  <img alt="" src={alertCircle} className={styles.errorImg}></img><label className={styles.error}>{t(error)}</label>
                              </div>
                              }
                            <label className={styles.label}>{t("phone")}</label>
                            <div className={styles.phoneInputDiv}>
                                <PhoneInput 
                                    value={phonenumber}
                                    onChange={e => setPhone(e)}
                                    placeholder="+910000000000"
                                    country={"in"}
                                      onEnterKeyPress={async (e) => {
                                        await setPhoneNum(e);
                                      }}
                                ></PhoneInput>
                            </div>
                            <button className={ phonenumber?styles.btn:styles.disabledBtn} type="submit">
                            {!apiCallComplete && <div className={styles.loader}>
                  <img alt="loader" className={styles.loaderImg} src={loader}></img></div>}
                 <div className={apiCallComplete?styles.buttonText:styles.buttonTextLoading}> {apiCallComplete === true ? t("login_button") : t("loading")}</div>
                            </button>
                          </form>
                     </div>
                    }
                    { (step === 2) &&
                      <div className={styles.innerContainer}>
                          <form onSubmit={verOtp}>
                              <label className={styles.subtitle}>{t("onboard_subtitle_phone")}</label>
                              <label className={styles.email}>{phonenumber}</label>
                              { showError && <div className={styles.errorDiv}>
                                  <img alt="" src={alertCircle} className={styles.errorImg}></img><label className={styles.error}>{t(error)}</label>
                              </div>
                              }
                            <OtpInput
                                numInputs={4}
                                inputStyle={styles.otpInputStyle}
                                containerStyle={styles.otpContainerStyle}
                                separator={<span>&nbsp;</span>}
                                value={otp}
                                onChange={(otpNo) => setOtp(otpNo)}
                                >
                            </OtpInput>
                            <button className={otp?styles.btn:styles.disabledBtn} type="submit">
                            {!apiCallComplete && <div className={styles.loader}>
                  <img alt="loader" className={styles.loaderImg} src={loader}></img></div>}
                 <div className={apiCallComplete?styles.buttonText:styles.buttonTextLoading}> {apiCallComplete === true ? t("login_button") : t("loading")}</div>
                            </button>
                          </form>
                          
                      </div>
                  }
                  { (step === 3) &&
                      <div className={styles.innerContainer}>
                          <form onSubmit={setPass}>
                              <label className={styles.verified}>{t("verified")}</label>
                               { showError && <div className={styles.errorDiv}>
                                  <img alt="" src={alertCircle} className={styles.errorImg}></img><label className={styles.error}>{t(error)}</label>
                              </div>
                              }
                            <label className={styles.label}>{t("create_password")}</label>
                            <div className={styles.inputDiv}>
                            <input className={styles.input} type= {isPassVisible?"text":"password"}  placeholder={t("create_pass_placeholder")} value={password} onChange={e => setPassword(e.target.value)} />
                            {isPassVisible?
                            <img className={styles.eyeIcon} onClick={()=>setIsPassVisible(!isPassVisible)} src={eyeOn} alt="eye"></img>:
                            <img className={styles.eyeIcon} onClick={()=>setIsPassVisible(!isPassVisible)} src={eyeOff} alt="eye"></img>
                            }
                            
                            </div>
                            <button className={password?styles.btn:styles.disabledBtn} type="submit">
                            {!apiCallComplete && <div className={styles.loader}>
                  <img alt="loader" className={styles.loaderImg} src={loader}></img></div>}
                 <div className={apiCallComplete?styles.buttonText:styles.buttonTextLoading}> {apiCallComplete === true ? t("login_button") : t("loading")}</div>
                            </button>
                          </form>
                      </div>
                  }
              </div>
              <div className={styles.footerLinks}>
            <a href="https://www.aurorahelse.com/terms-and-conditions" style={{color:'#000'}} >{t("Terms of Use")}</a>
            <div className={styles.divider}>
                <a href="https://www.aurorahelse.com/privacy-policy" style={{color:'#000'}} >{t("Privacy Policy")}</a>
            </div>
        </div>
          </div>

              <Modal isOpen={showModal} className={styles.modalStyles} overlayClassName={styles.modalOverlay} >
                <div className={styles.modalCloseDiv}><img alt="" className={styles.modalCloseImage} onClick={closeModal} src={close}></img></div>
              <div className={styles.modalContainer}>
                  <img alt="" src={modal_check}></img>
                  <p className={styles.modTitle}>{(t("modal_title"))}</p>
                  <p className={styles.modSubTitle}>{(t("modal_subtitle"))}</p>
                  <button className={styles.btnLogin} onClick={closeModal}><div className={styles.buttonText}>{(t("login"))}</div></button>
                </div>
              </Modal>
    </div>
  );


};
OnboardUser.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(OnboardUser);
