import React, { useEffect } from "react";
import SubHeader from "./SubHeader/SubHeader";
import styles from "../Appointment/BookingSteps.module.css";
import SideBox from "./SideBox/SideBox";
import Verification from "./Verification/Verfication";
import Stepper from "./Stepper/Stepper";
import { useState } from "react";
import moment from "moment";
import { bookAppointment } from "../../api/index";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import Modal from "../NewDashboard/ServiceModal/Modal";
import TimeSlotAlreadyBookedModal from "./TimeSlotAlreadyBookedModal";
import loadingAnimation from "../../assets/images/logo-animation.svg";
import Navbar from "../Navbar/Navbar";
import { useToasts } from "react-toast-notifications";

const BookingSteps = ({ ...props }) => {
  const { t } = props;
  const [activeStep, setActiveStep] = useState(1);
  const [doc, setDoc] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [note, setNote] = useState("");
  const [nextDisable, setNextDisable] = useState("true");
  const [, setPhone] = useState();
  const [, setDisableBA] = useState("true");
  const [viewModal] = useState("none");
  const [isLoading, setIsLoading] = useState(false);
  const [isInHouseDoc, setIsInHouseDoc] = useState(false);
  const [showTimeSlotAlreadyBookedModal, setShowTimeSlotAlreadyBookedModal] =
    useState(false);
  const { addToast } = useToasts();

  const history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      history.push("/dashboard");
    }

    setNextDisable("true");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getNotes = (value) => {
    setNote(value);
  };

  const getVerificationDetails = (value1, value2) => {
    setPhone(value1);
    if (value2 === "true") {
      setDisableBA("false");
    } else {
      setDisableBA("true");
    }
  };

  //Get Selected Doctor, Date and Time
  const getData = async (value1, value2, value3) => {
    setDoc(value1);
    setDate(value2);
    setTime(value3);
    if (value1) {
      if (value1.isInHouseDoc != null) {
        setIsInHouseDoc(value1.isInHouseDoc);
      } else {
        setIsInHouseDoc(value1.is_inhouse_doctor);
      }
    }
    if (value3 && value2) {
      if (value1 && value2 && value3) {
        setNextDisable("false");
      } else {
        setNextDisable("true");
      }
    }
  };

  const createAppointment = async () => {
    setIsLoading(true);
    // const a = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    const start = moment(date, "DD-MM-YYYY")
      .format("YYYY-MM-DD")
      .concat("T" + time.timestamp + ":00+00:00");
    const end = moment(date, "DD-MM-YYYY")
      .format("YYYY-MM-DD")
      .concat("T" + time.timestamp_end + ":00+00:00");
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    const data = {
      service_id: props.location.state.service_id.id,
      resource_id: doc.id,
      resource_name: doc.name,
      timestamp: start,
      timestamp_end: end,
      notes: note,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      person_phonenumber: user.phoneNumber,
      person_email: user.emailId,
      person_name: user.firstName,
    };
    const service = props.location.state.title;
    try {
      const response = await bookAppointment(data);
      if (response === "timeslot_booked") {
        toggleTimeSlotAlreadyBookedModalDisplay();
      } else if (response === "Something went wrong") {
        window.location.reload();
      } else {
        if (response.data.isFirstTimeBooking === true) {
          history.push({
            pathname: "./summary",

            state: {
              doctor: doc,
              selectedDate: { date },
              selectedTime: { time },
              notes: { note },
              service: { service },
            },
          });
        } else {
          setIsLoading(false);

          history.push({
            pathname: "./payment",
            state: {
              doctor: doc,
              selectedDate: { date },
              selectedTime: { time },
              notes: { note },
              service: { service },
              paymentObj: response.data.payment_id,
              bookingId: response.data.id,
            },
          });
        }
      }
    } catch (error) {
      addToast("Booking failed. Please try again", {
        appearance: "error",
        autoDismiss: true,
        onDismiss: () => {
          history.push("/dashboard");
        },
      });
    }
  };

  const toggleTimeSlotAlreadyBookedModalDisplay = () => {
    if (showTimeSlotAlreadyBookedModal === true) {
      setShowTimeSlotAlreadyBookedModal(false);
      window.location.reload();
    } else {
      setShowTimeSlotAlreadyBookedModal(true);
    }
  };

  return (
    <React.Fragment>
      <TimeSlotAlreadyBookedModal
        showModal={showTimeSlotAlreadyBookedModal}
        service_id={props.location.state.service_id}
        closeModal={() => toggleTimeSlotAlreadyBookedModalDisplay()}
      ></TimeSlotAlreadyBookedModal>
      {viewModal === "flex" ? (
        <Modal title={props.location.state.title}></Modal>
      ) : (
        <p style={{ display: "none" }}>hello</p>
      )}
      <div id="book" className={styles.coverDiv}>
        <Navbar></Navbar>
        <div className={styles.mainContainer}>
          <SubHeader
            service_id={props.location.state.service_id}
            isFreeAppointment={
              isInHouseDoc && props.location.state.isFirstAppointment
            }
            selectedDoc={doc ? doc.name : ""}
          ></SubHeader>

          <div className={styles.contentWrapper}>
            <SideBox id="side"></SideBox>

            {isLoading === true ? (
              <div className={styles.loadingDiv}>
                <img src={loadingAnimation} alt="loading"></img>
              </div>
            ) : (
              <div className={styles.bookingStepsWrapper}>
                {
                  activeStep === 1 ? (
                    <Stepper
                      finalDoc={getData}
                      service_id={props.location.state.service_id}
                      isFree={
                        isInHouseDoc && props.location.state.isFirstAppointment
                      }
                    ></Stepper>
                  ) : (
                    <Verification
                      finalVerify={getVerificationDetails}
                      getNotes={getNotes}
                    ></Verification>
                  )
                  /* activeStep === 2 ? <AppointmentSummary></AppointmentSummary>: */
                }
                {activeStep === 1 ? (
                  <div className={`${styles.buttonDiv} buttonDivCompanyStyles`}>
                    <div
                      className={`${styles.backButtonWrapper} backButtonWrapperCompnayStyles`}
                      onClick={handleBack}
                    >
                      {/* <img
                        className={styles.btnArrow}
                        alt="arrow"
                        src={leftArrow}
                      ></img> */}
                      <p className={styles.backText}>{parse(t("Back"))}</p>
                    </div>
                    <p
                      style={{ display: time ? "block" : "none" }}
                      className={`${styles.almost} almostTextCompanyStyles`}
                    >
                      {parse(
                        t("Almost There, Just proceed to mobile verification")
                      )}
                    </p>
                    <button
                      className={`${styles.nextBtn} nxtBtnCompanyStyles`}
                      onClick={handleNext}
                      style={{
                        opacity: nextDisable === "true" ? 0.4 : 1,
                        pointerEvents: nextDisable === "true" ? "none" : "auto",
                      }}
                    >
                      <p className={styles.next}>Next</p>
                      {/* <img
                        className={styles.btnArrow}
                        alt="arrow"
                        src={arrow}
                      ></img> */}
                    </button>
                    {/* <button onClick={handleNext} disabled={nextDisable} style={{ fontSize: '16px', width:'200px' }} className={styles.cardBtn}>{nextDisable}<span> <i style={{ marginLeft: '10px' }} className="fas fa-chevron-right"></i></span></button> */}
                  </div>
                ) : (
                  <div className={`${styles.buttonDiv} buttonDivCompanyStyles`}>
                    <div
                      className={`${styles.backButtonWrapper} backButtonWrapperCompnayStyles`}
                      onClick={handleBack}
                    >
                      {/* <img
                        className={styles.btnArrow}
                        alt="arrow"
                        src={leftArrow}
                      ></img> */}
                      <p className={styles.backText}>{parse(t("Back"))}</p>
                    </div>

                    <button
                      className={`${styles.nextBtn} nxtBtnCompanyStyles`}
                      onClick={createAppointment}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={styles.next}>
                        {parse(t("Book Appointment"))}
                      </p>
                      {/* <img
                        className={styles.btnArrow}
                        alt="arrow"
                        src={arrow}
                      ></img> */}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(BookingSteps);
