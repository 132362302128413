import React from "react";
import styles from "../Cards/Cards.module.css";
import arrow from "../../../assets/images/arrow.svg";
import { fetchServices } from "../../../api/index";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const Cards = ({ ...props }) => {
  const { t } = props;
  const history = useHistory();
  const [services, setServices] = useState([]);
  let count = 0;

  useEffect(() => {
    const getService = async () => {
      const response = await fetchServices();

      let services = response.data["services"];

      const filteredServices = services;
      setServices(filteredServices.sort((a, b) => a.id - b.id));
    };
    getService();
  }, []);

  const handleServices = (docid) => {
    history.push({
      pathname: "/booking",
      state: {
        service_id: docid,
        title: docid.title,
        isFirstAppointment: props.isFirstAppointment,
      },
    });
  };

  const handleClick = (service) => {
    switch (service["id"]) {
      default:
        handleServices(service);
        break;
    }
  };

  if (services) {
    return (
      <div className={`${styles.cardsWrapper} cardsWrapperCompanyStyles`}>
        {services.map((service) => {
          if (count >= 1) {
            return <div></div>;
          }
          count++;
          return (
            <div
              className={`${styles.serviceCard} serviceCardCompanyStyles`}
              key={service.id}
            >
              <div
                className={`${styles.serviceCardBtnRow} serviceCardBtnRowCompanyStyles`}
              >
                <img
                  src={service.image}
                  alt="illustration"
                  className={`${styles.illustration} illustrationCompanyStyles`}
                ></img>
              </div>
              <div className={styles.description}>
                <p
                  className={`${styles.serviceTitle} serviceTitleCompanyStyles`}
                >
                  Book a professional
                </p>
                <p
                  className={`${styles.serviceDescription} serviceDescriptionCompanyStyles`}
                >
                  Book a professional appointment easily and conveniently
                  online. Access top professionals at your preferred time with
                  just a few clicks.
                </p>
              </div>
              <button
                className={`${styles.serviceBookBtn} serviceBookBtnCompanyStyles `}
                key={service.id}
                onClick={() => handleClick(service)}
              >
                {parse(t("Book"))}
                <img
                  src={arrow}
                  alt="arrow"
                  className={`${styles.btnArrow} serviceCardBtnArrowCompanyStyles`}
                ></img>
              </button>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <p></p>;
  }
};
export default withTranslation()(Cards);
