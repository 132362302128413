import React from "react";
import {
  addDays,
  addMonths,
  format,
  lastDayOfMonth,
  startOfMonth,
} from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { nb, enUS } from "date-fns/locale";
import styles from "./AppointmentCalendar.module.css";
import { getAvailableDates } from "../../../api/index";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import ScrollNext from "../../../ReusableComponents/ScrollNext/ScrollNext";
import ScrollPrevious from "../../../ReusableComponents/ScrollPrevious/ScrollPrevious";

const AppointmentCalendar = ({ ...props }) => {
  const { t } = props;

  const startDate = new Date();
  const endDate = addDays(startDate, 90);
  const [availableDates, setAvailableDates] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [activeDates, setActiveDates] = useState();
  const [pgStartDate, setPageStartDate] = useState();

  const today = new Date();
  useEffect(() => {
    let end = moment(today).add(6, "d").format("YYYY-MM-DD");
    setPageStartDate(end);
    const getDates = async () => {
      setAvailableDates(props.doctorInfo);
    };
    getDates();
  }, [props.doctorInfo]);

  useEffect(() => {
    setActiveDates();
    setSelectedDate();
    if (props.selectedDoc && availableDates) {
      //Filter dates where doctors have slots and store into active dates
      const doctorDates = props.doctorInfo.filter(
        (date) =>
          date.resources.length > 0 &&
          date.resources.some(
            (k) => k.slots.length > 0 && k.id === props.selectedDoc.id
          )
      );
      if (doctorDates.length > 0) {
        let [day, month, year] = doctorDates[0].date.split("-");
        setSelectedDate(doctorDates[0].date);
        setActiveDates(doctorDates);
        getSlots(new Date(+year, +month - 1, +day), doctorDates);
      }
    }
  }, [props.selectedDoc]);

  const convertDate = (f) => {
    //convert to DD-MM-YYYY format
    let d = ("0" + f.getDate()).slice(-2);
    let m = ("0" + (f.getMonth() + 1)).slice(-2);
    let y = f.getFullYear();

    let fullDate = d + "-" + m + "-" + y;
    return fullDate;
  };

  const fetchMoredays = async () => {
    //Fetch dated for next 7 days
    let s = moment(pgStartDate).add(1, "d").format("YYYY-MM-DD");
    let e = moment(pgStartDate).add(7, "d").format("YYYY-MM-DD");
    setPageStartDate(e);
    const response = await getAvailableDates(props.service_id.id, s, e);
    const d = availableDates.concat(response);

    const active = response.filter(
      (date) =>
        date.resources.length > 0 &&
        date.resources.some(
          (k) => k.slots.length > 0 && k.id === props.selectedDoc.id
        )
    );
    setSelectedDate(active[0].date);
    const temp = activeDates.concat(active);
    setActiveDates(temp);
  };

  const getTimeSlots = (doctorObject, day) => {
    //Get slots on a date
    let timeSlots = [];
    let availableTimeSlots = doctorObject.resources.filter(
      (doc) => doc.id === props.selectedDoc.id
    );
    availableTimeSlots[0].slots.map((t) => {
      timeSlots.push(t);
    });
    return timeSlots;
  };

  const getStyles = (day, month) => {
    if (convertDate(day) === selectedDate) {
      return "selected";
    } else if (checkDoctorAvailability(convertDate(day))) {
      return "available";
    }
    return "disbaled";
  };

  const checkDoctorAvailability = (day) => {
    if (activeDates) {
      const a = activeDates.some((d) => d.date === day);
      return a;
    }
  };

  const scrollNextWeek = () => {
    const e = document.getElementById("scroll");
    const width = e.getBoundingClientRect().width;
    e.scrollLeft += width - 0;
    fetchMoredays();
  };

  const scrollPrevWeek = () => {
    const e = document.getElementById("scroll");
    const width = e.getBoundingClientRect().width;
    e.scrollLeft -= width - 0;
  };

  const dateSelection = (day) => {
    //When user selects a date get time slots and send back
    setSelectedDate(convertDate(day));

    getSlots(day, activeDates);
  };

  const getSlots = (day, arr) => {
    props.selectedDate(day);
    const getDate = arr.filter((d) => d.date === convertDate(day));
    const slots = getTimeSlots(getDate[0]);
    if (slots) {
      props.timeSlots(slots);
    }
  };

  function renderDays() {
    let start, end;
    const dayFormat = "eee";
    const dateFormat = "d";
    let days = [];
    let months = [];
    for (let i = 0; i <= 3; i++) {
      const month = startOfMonth(addMonths(startDate, i));
      //const year = getYear(startDate);
      start =
        i === 0 ? Number(format(addDays(startDate, 0), dateFormat)) - 1 : 0;
      end =
        i === 3
          ? Number(format(endDate, "d"))
          : Number(format(lastDayOfMonth(month), "d"));
      for (let j = start; j < end; j++) {
        days.push(
          <div
            className={`${styles.subDiv1} dateWrapperCompanyStyles ${
              getStyles(addDays(month, j), month) === "selected"
                ? `${styles.selectedStyle} selectedDateCompanyStyle`
                : getStyles(addDays(month, j), month) === "available"
                ? `${styles.availableStyle} availableDateCompanyStyle`
                : `${styles.disabledStyle} disabledDateCompanyStyle`
            }`}
            key={addDays(month, j)}
            onClick={() => dateSelection(addDays(month, j))}
          >
            <div
              className={`${styles.bookingDateContent} ${styles.bookingDateDay} bookingDateContentAurora`}
            >
              {format(addDays(month, j), dayFormat, { locale: nb })}
            </div>
            <div
              className={`${styles.bookingDateContent} ${styles.bookingDateDay} bookingDateContentAurora`}
            >
              {format(addDays(month, j), dateFormat, { locale: nb })}
            </div>

            <div
              className={`${styles.bookingDateContent}  bookingDateContentCompanyStyles`}
            >
              {format(addDays(month, j), dateFormat, { locale: enUS })}
            </div>
            <div
              className={`${styles.bookingDateContent} ${styles.bookingDateDay} bookingDateContentCompanyStyles`}
            >
              {format(month, "MMM", { locale: enUS })}
            </div>
            <div
              className={`${styles.bookingDateContent} ${styles.bookingDateDay} ${styles.dayName} bookingDateContentCompanyStyles`}
            >
              {format(addDays(month, j), dayFormat, { locale: enUS })}
            </div>
          </div>
        );
      }

      months.push(
        // <div key={i} className={styles.monthDiv}>
        // {/* <div className={`${styles.monthLabelDiv} monthLabelCompanyStyle`}>
        //   <div className={styles.monthLabel}>
        //     <img
        //       src={logoSmall}
        //       alt="calendar"
        //       className={styles.calendarImage}
        //     ></img>
        //     {format(month, "MMMM", { locale: enUS })}
        //   </div>
        // </div> */}

        // /* {/* </div> */} */
        <div className={styles.datesDiv}>{days}</div>
      );
      days = [];
    }
    return (
      <div id="scroll" className={styles.secondDiv1}>
        {months}
      </div>
    );
  }
  if (props.selectedDoc) {
    return (
      <div className={styles.containDiv}>
        <div className={styles.calendarDiv1}>
          {/* <div className={styles.buttonWrapper1}>
            <img src={leftArrow} alt="arrow" onClick={scrollPrevWeek}></img>
          </div> */}
          <ScrollPrevious onClick={scrollPrevWeek}></ScrollPrevious>

          {renderDays()}

          {/* <div className={styles.buttonWrapper2}>
            <img src={rightArrow} alt="arrow" onClick={scrollNextWeek}></img>
          </div> */}
          <ScrollNext onClick={scrollNextWeek}></ScrollNext>
        </div>
      </div>
    );
  } else {
    return <p>{parse(t("No dates available"))}</p>;
  }
};

export default withTranslation()(AppointmentCalendar);
