import { useState } from "react";
import React, { useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import styles from "./DashboardRedesign.module.css";
import mainImg from "../../assets/images/dashboard-main.svg";

export default function Header({ ...props }) {
  const history = useHistory();

  const goToBookings = (docid) => {
    history.push({
      pathname: "/booking",
      state: {
        service_id: props.service,
        title: "",
        isFirstAppointment: props.isFirstAppointment,
      },
    });
  };

  return (
    <>
      <Navbar></Navbar>
      <div class={styles.main_wrapper}>
        <div class={styles.main_section}>
          <div class={styles.main_heading}>
            <h1>This is your safe space</h1>
            <p>We help you identify vulnerabilities and recommend programs</p>
          </div>
          <div class={styles.main_box}>
            <h3>Let’s Talk</h3>
            <p>
              Book a professional appointment easily and coveniently online.
              Talk to our curated professionals at your preferred time
            </p>
            <button onClick={() => goToBookings()} className={styles.bookBtn}>
              Book a session
            </button>
          </div>
        </div>
        <img src={mainImg} alt="main_bg" class={styles.main_bg} />
      </div>
    </>
  );
}
