import React from "react";
import styles from "./LeftPanel.module.css";
import { withTranslation } from "react-i18next";
import logo from "../../../assets/images/moodahead/moodahead_white_logo.svg";
import illustrations from '../../../assets/images/signup_mood.svg';

const LeftPanel = ({ t }) => {
    return (
    <div className={styles.leftContainer}>
        <img className={styles.logoImg} alt="logo" src={logo}></img>
        <img className={styles.illustration} src={illustrations} alt="illustration"></img>
        {/* <p className={styles.leftHeader}>{t("left_header")}</p> */}
    </div>
  );
};
export default withTranslation()(LeftPanel);
