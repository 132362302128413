import styles from "../Appointment/BookingSteps.module.css";
const PaymentMethodFailed = () => {
  return (
    <div className={styles.paymentfailed}>
      Payment Failed !!
      <a href="/dashboard" className={styles.dashboardLink} >Go to dashboard</a>
    </div>
  );
};

export default PaymentMethodFailed;
