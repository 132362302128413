import React from "react";
import styles from "../SubHeader/SubHeader.module.css";
import { fetchServiceDoctors } from "../../../api/index";
import { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import freeAppointmentImg from "../../../assets/images/free_appointment_img.svg";

const SubHeader = ({ ...props }) => {
  const { t } = props;
  const [doctor, setDoctor] = useState();
  const [service, setService] = useState();

  useEffect(() => {
    const getDoctors = async () => {
      setService(props.service_id);
      const response = await fetchServiceDoctors(props.service_id.id);
      setDoctor(response.data);
    };
    getDoctors();
  }, []);

  const additionalDoctors = () => {
    //Fetch doctors of a Service
    let doc = [];
    let i = 1;
    let k = doctor.length - 1;
    let j;
    for (i = 0; i < doctor.length; i++, k--) {
      let firstChar = doctor[i].name.charAt(0);
      j = 10 * k - 10;
      doc.push(
        <img
          key={i}
          src={
            doctor[i].image == null
              ? `https://moodahead.s3.eu-central-1.amazonaws.com/moodahead-public/alphabetic-placeholders/${firstChar}.png`
              : doctor[i].image
          }
          alt="doctor"
          className={styles.additionalDocImg}
          style={{ /* zIndex: `${i}`, */ position: "relative", left: `${j}%` }}
        ></img>
      );
    }

    if (doctor.length > 1) {
      return doc;
    } else {
      return doc;
    }

    //return doc;
  };

  if (service)
    return (
      <React.Fragment>
        <div className={styles.serviceDescriptionWrapper}>
          <div className={styles.serviceDetails}>
            <img
              className={styles.serviceImg}
              alt="doctor"
              src={service.image}
            ></img>
            <div className={styles.serviceTextWrapper}>
              <p className={styles.serviceName}>Book a professional</p>
              <p className={styles.serviceDescription}>
                We help you identify vulnerabilities and recommend programs
              </p>
              <div className={styles.responsiveDoctorsWrapper}>
                {doctor ? additionalDoctors() : <p>{parse(t("Loading..."))}</p>}
              </div>
            </div>
          </div>
          {props.isFreeAppointment ? (
            <div className={styles.freeAppointmentContainer}>
              <img
                // className={styles.serviceImg}
                alt="doctor"
                src={freeAppointmentImg}
              ></img>
              <span className={styles.freeAppointmentText}>
                Yay! Your first session is free with {props.selectedDoc}
              </span>
            </div>
          ) : (
            <div></div>
          )}
          {/* <div className={styles.doctorsWrapper}>
            {doctor ? additionalDoctors() : <p>{parse(t("Loading..."))}</p>}
          </div> */}
        </div>
        <hr className={styles.headingHr}></hr>
      </React.Fragment>
    );
  else {
    return <p>{parse(t("Loading..."))}</p>;
  }
};

export default withTranslation()(SubHeader);
