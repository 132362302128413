import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import logo from "../../assets/images/logo.svg";
import { cancelBooking } from "../../api";
import { useToasts } from "react-toast-notifications";

const Payments = ({ ...props }) => {
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    displayRazorpayPaymentSdk(props.location.state.paymentObj);
  }, []);

  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function cancelAppointment(call) {
    try {
      await cancelBooking(props.location.state.bookingId).then(() => {
        call();
      });
    } catch (error) {
      console.log("Payment failed redirection");
      addToast("Booking failed. Please try again", {
        appearance: "error",
        autoDismiss: true,
        onDismiss: () => {
          history.push("/dashboard");
        },
      });
      throw error;
    }
  }

  async function displayRazorpayPaymentSdk(paymentObj) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }

    const merchantId = paymentObj.merchant_id;
    const amount = paymentObj.amount;
    const currency = paymentObj.amount_currency;
    const orderId = paymentObj.order_id;

    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    const options = {
      modal: {
        ondismiss: () => {
          cancelAppointment(() => {
            history.push("/dashboard");
          });
        },
      },
      key: merchantId,
      amount: amount.toString(),
      currency: currency,
      name: "Moodahead payment",
      description: `Pay ${amount} ${currency} for your booking.`,
      image: { logo },
      order_id: orderId,
      onclose: () => {
        cancelAppointment(() => {
          history.push("/dashboard");
        });
      },

      handler: function (response) {
        const service = props.location.state.title;
        history.push({
          pathname: "./summary",
          state: {
            doctor: props.location.state.doctor,
            selectedDate: props.location.state.selectedDate,
            selectedTime: props.location.state.selectedTime,
            notes: props.location.state.notes,
            service: { service },
          },
        });
      },

      prefill: {
        name: user.firstName,
        email: user.emailId,
        contact: user.phoneNumber,
      },
      theme: {
        color: "rgba(11, 156, 255, 0.08)",
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.open();

    paymentObject.on("payment.failed", function (response) {
      if (
        response.error.reason === "payment_cancelled" ||
        response.error.reason === "payment_failed"
      ) {
        paymentObject.close();
        cancelAppointment(() => {
          history.push({
            pathname: "./payment-failed",
          });
        });
      }
    });
  }

  return (
    <>
      <p style={{ color: "transparent" }}>Payments</p>
    </>
  );
};

export default withTranslation()(Payments);
