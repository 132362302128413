import styles from "../Welcome/Welcome.module.css";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import logo from "../../assets/images/logo.svg";
import arrow from "../../assets/images/arrow.svg";
import { useEffect } from "react";
import { useHistory } from "react-router";

const Welcome = ({ ...props }) => {
  const { t } = props;
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  useEffect(() => {
    const setTutorialInLocal = async () => {
      user.tutorialVisited = "False";
      localStorage.setItem("user", JSON.stringify(user));
    };
    setTutorialInLocal();
  }, []);

  const handleClick = () => {
    history.push({ pathname: "/dashboard" });
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.imgDiv}>
        <img alt={"welcome"}></img>
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.leftDiv}>
          <img src={logo} alt={"Logo"}></img>
          <p>
            {parse(t("Hello There"))}, {user.firstName} <br></br>
            {parse(t("Welcome to your healthcare account"))}
          </p>
        </div>
        <button className={styles.cardBtn} onClick={() => handleClick()}>
          <p className={styles.next}>{parse(t("Continue"))}</p>
          <img className={styles.btnArrow} alt="arrow" src={arrow}></img>
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(Welcome);
