import React, { useEffect } from "react";
import styles from "./AppointmentSummary.module.css";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import moment from "moment";
import Navbar from "../../Navbar/Navbar";

const AppointmentSummary = ({ ...props }) => {
  const { t } = props;
  const location = useLocation();
  const history = useHistory();

  const clickHandle = () => {
    history.push("/dashboard");
  };

  const convertIntoLocalTime = (timeSlot) => {
    var dateParts = location.state.selectedDate.date
      .toLocaleString()
      .split("-");
    var t_split = timeSlot.split(":");
    //year, month, day, hours, minutes, seconds, millisec
    var date = new Date(
      Date.UTC(
        dateParts[2],
        dateParts[1] - 1,
        dateParts[0],
        t_split[0],
        t_split[1],
        0
      )
    );
    var options = { hour12: false, hour: "2-digit", minute: "2-digit" };
    var time = date.toLocaleTimeString([], options);
    return time;
  };
  let firstChar = location.state.doctor.name.charAt(0);

  return (
    <React.Fragment>
      <Navbar></Navbar>
      <div className={styles.mainContainer}>
        <p className={styles.headingText}>Your Appointment has been Booked.</p>
        {/* <div className={styles.serviceName}>
          {location.state.service.service}
        </div> */}
        <div className={styles.contentWrapper}>
          <div className={styles.doctorDetailsWrapper}>
            <img
              src={
                location.state.doctor.image == null
                  ? `https://moodahead.s3.eu-central-1.amazonaws.com/moodahead-public/alphabetic-placeholders/${firstChar}.png`
                  : location.state.doctor.image
              }
              alt="doctor"
              className={styles.docImg}
            ></img>
            <div className={styles.doctorDetail}>
              <p className={styles.meetingWithText}>
                Appointment with {location.state.doctor.name}
              </p>

              <p className={styles.time}>
                {parse(t("Booked on"))}{" "}
                {moment(new Date()).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>
          <div className={styles.dateContainer}>
            <div className={styles.date}>
              <p>
                <span className={styles.scheduleOn}>Scheduled on : </span>
                {location.state.selectedDate.date}
              </p>
            </div>
            <div className={styles.date}>
              <p>
                <span className={styles.scheduleOn}>
                  {parse(t("Time slot"))} :{" "}
                </span>
                {convertIntoLocalTime(
                  location.state.selectedTime.time.timestamp
                )}
              </p>
            </div>
          </div>
          {/* {location.state.notes.note && (
            <div className={styles.note}>
              <p>{parse(t("Your messaage to the Doctor"))}</p>
              <div className={styles.notesTextbox}>
                <p>"{location.state.notes.note}"</p>
              </div>
            </div>
          )} */}
        </div>
        <div className={styles.infoText}>
          <p>
            {parse(
              t(
                "We will send a video conference link to the verified number. Open the link to begin your consultation."
              )
            )}
          </p>
          <button onClick={clickHandle} className={styles.finishBtn}>
            {parse(t("Done !"))}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AppointmentSummary);
