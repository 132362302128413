import React, { useState, useEffect } from "react";
import styles from "./NewLogin.module.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import auth from "../Auth/auth";
import LeftPanel from "./LeftPanel/LeftPanel";
import OtpInput from "react-otp-input";
import { verifyUserOtp, verifyPassword, registerUser } from "../../api";
import alertCircle from "../../assets/images/alert-circle1.svg";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/images/moodahead/moodahead_primary_logo.svg";
import loader from "../../assets/images/loader.gif";
import { useToasts } from "react-toast-notifications";

const NewLogin = (props) => {
  const [step, setStep] = useState(1);
  const [phonenumber, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpHash, setOtpHash] = useState("");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [apiCallComplete, setApiCallComplete] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [isLogin, setIsLogin] = useState(false);
  const { addToast } = useToasts();
  const { t } = props;
  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        const isLoggedIn = await auth.isAuthenticated();
        if (isLoggedIn) {
          props.history.push("/dashboard");
        } else {
          props.history.push("/login");
        }
      } catch (err) {
        console.log(err);
      }
    };
    isUserLoggedIn();
  }, []);

  useEffect(() => {
    setShowError(false);
    setError("");
    setPhone("+91");
  }, [isLogin]);

  const verifyPass = async (e) => {
    e.preventDefault();
    setShowError(false);
    setApiCallComplete(false);
    try {
      const res = await verifyPassword(phonenumber);
      if (res.otpHash) {
        setOtpHash(res.otpHash);
        setApiCallComplete(true);
        setStep(2);
      }
    } catch (error) {
      setError(error.response.data.error);
      setShowError(true);
    } finally {
      setApiCallComplete(true);
    }
  };

  const signUp = async (e) => {
    e.preventDefault();
    setShowError(false);

    const regex = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (firstName.replace(/\s/g, "").length === 0 || firstName.length === 0) {
      addToast("Invalid first name", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (
      lastName.replace(/\s/g, "").length === 0 ||
      lastName.length === 0
    ) {
      addToast("Invalid last name", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (!emailRegex.test(email)) {
      addToast("Invalid email", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (!regex.test("+" + phonenumber)) {
      addToast("Invalid phone number", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setApiCallComplete(false);
      try {
        const res = await registerUser(
          firstName,
          lastName,
          email,
          gender,
          phonenumber
        );
        if (res) {
          const res = await verifyPassword(phonenumber);
          if (res.otpHash) {
            setOtpHash(res.otpHash);
            setApiCallComplete(true);
            setStep(2);
          }
        }
      } catch (error) {
        setError(error.response.data.message);
        setShowError(true);
      } finally {
        setApiCallComplete(true);
      }
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setShowError(false);
    setApiCallComplete(false);
    try {
      const res = await verifyUserOtp(phonenumber, otpHash, otp);
      auth.saveSessionDataInLocalStorage(res);
      props.history.push("/dashboard");
    } catch (error) {
      setError(error.response.data.error);
      setShowError(true);
    } finally {
      setApiCallComplete(true);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <LeftPanel></LeftPanel>
      <div className={styles.rightContainer}>
        <div className={styles.outerContainer}>
          <img className={styles.logoImg} alt="logo" src={logo}></img>
          <div className={styles.title}>
            {isLogin && step === 1
              ? t("login_title")
              : step === 2
              ? "Enter OTP"
              : "Sign up"}
          </div>
          <div className={styles.stepper}>
            <div
              className={
                step >= 1 ? styles.stepperActive : styles.stepperInactive
              }
            ></div>
            <div
              className={
                step >= 2 ? styles.stepperActive : styles.stepperInactive
              }
            ></div>
          </div>

          {step === 1 && isLogin && (
            <div className={styles.innerContainer}>
              <form onSubmit={verifyPass}>
                {showError && (
                  <div className={styles.errorDiv}>
                    <img
                      alt=""
                      src={alertCircle}
                      className={styles.errorImg}
                    ></img>
                    <label className={styles.error}>{t(error)}</label>
                  </div>
                )}
                <label className={styles.label}>Phone</label>
                <div className={styles.phoneInputDiv}>
                  <PhoneInput
                    placeholder="+910000000000"
                    country={"in"}
                    value={phonenumber}
                    onChange={(e) => setPhone(e)}
                  ></PhoneInput>
                </div>

                <button
                  type="submit"
                  className={phonenumber ? styles.btn : styles.disabledBtn}
                >
                  {!apiCallComplete && (
                    <div className={styles.loader}>
                      <img
                        alt="loader"
                        className={styles.loaderImg}
                        src={loader}
                      ></img>
                    </div>
                  )}
                  <div
                    className={
                      apiCallComplete
                        ? styles.buttonText
                        : styles.buttonTextLoading
                    }
                  >
                    {" "}
                    {apiCallComplete === true
                      ? t("login_button")
                      : t("loading")}{" "}
                  </div>
                </button>
                <span style={{ textAlign: "center" }}>
                  <label
                    className={styles.contactLabel}
                    style={{ marginRight: "5px" }}
                  >
                    {t("contact_label")}
                  </label>
                  <label
                    className={styles.contactLink}
                    onClick={() => setIsLogin(false)}
                  >
                    {t("Sign up")}
                  </label>
                </span>
              </form>
            </div>
          )}
          {step === 1 && !isLogin && (
            <div className={styles.innerContainer}>
              <form onSubmit={signUp}>
                {showError && (
                  <div className={styles.errorDiv}>
                    <img
                      alt=""
                      src={alertCircle}
                      className={styles.errorImg}
                    ></img>
                    <label className={styles.error}>{t(error)}</label>
                  </div>
                )}
                <label className={styles.label}>{t("First name")}</label>
                <div className={styles.inputDiv}>
                  <input
                    className={styles.input}
                    type="text"
                    placeholder="John"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <label className={styles.label}>{t("Last name")}</label>
                <div className={styles.inputDiv}>
                  <input
                    className={styles.input}
                    type="text"
                    placeholder="Doe"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <label className={styles.label}>{t("Email")}</label>
                <div className={styles.inputDiv}>
                  <input
                    className={styles.input}
                    type="text"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <label className={styles.label}>{t("Gender")}</label>
                <div className={styles.genderWrapper}>
                  <div
                    className={styles.radioInputDiv}
                    onClick={(e) => setGender("male")}
                  >
                    <input
                      className={styles.radioInput}
                      type="radio"
                      value="male"
                      radioGroup="gender"
                      name="male"
                      checked={gender === "male"}
                    />
                    <span>Male</span>
                  </div>
                  <div
                    className={styles.radioInputDiv}
                    onClick={(e) => setGender("female")}
                  >
                    <input
                      className={styles.radioInput}
                      type="radio"
                      value="female"
                      radioGroup="gender"
                      name="female"
                      checked={gender === "female"}
                    />
                    <span>Female</span>
                  </div>
                  <div
                    className={styles.radioInputDiv}
                    onClick={(e) => setGender("other")}
                  >
                    <input
                      className={styles.radioInput}
                      type="radio"
                      value="other"
                      radioGroup="gender"
                      name="other"
                      checked={gender === "other"}
                    />
                    <span>Other</span>
                  </div>
                </div>
                <label className={styles.label}>{t("Phone")}</label>
                <div className={styles.phoneInputDiv}>
                  <PhoneInput
                    placeholder="+910000000000"
                    value={phonenumber}
                    onChange={(e) => setPhone(e)}
                    country={"in"}
                  ></PhoneInput>
                </div>
                <button
                  type="submit"
                  className={
                    firstName && lastName && email && phonenumber
                      ? styles.btn
                      : styles.disabledBtn
                  }
                >
                  {!apiCallComplete && (
                    <div className={styles.loader}>
                      <img
                        alt="loader"
                        className={styles.loaderImg}
                        src={loader}
                      ></img>
                    </div>
                  )}
                  <div
                    className={
                      apiCallComplete
                        ? styles.buttonText
                        : styles.buttonTextLoading
                    }
                  >
                    {" "}
                    {apiCallComplete === true
                      ? t("Sign up")
                      : t("loading")}{" "}
                  </div>
                </button>
                <span style={{ textAlign: "center" }}>
                  <label
                    className={styles.contactLabel}
                    style={{ marginRight: "5px" }}
                  >
                    {t("Already have an account?")}
                  </label>
                  <label
                    className={styles.contactLink}
                    onClick={() => {
                      setIsLogin(true);
                    }}
                  >
                    {t("Login")}
                  </label>
                </span>
              </form>
            </div>
          )}

          {step === 2 && (
            <div className={styles.innerContainer}>
              <form onSubmit={verifyOtp}>
                <label className={styles.otpLabel}>{t("otp_label")}</label>
                <label className={styles.phoneNumber}>{phonenumber}</label>
                {showError && (
                  <div className={styles.errorDiv}>
                    <img
                      alt=""
                      src={alertCircle}
                      className={styles.errorImg}
                    ></img>
                    <label className={styles.error}>{t(error)}</label>
                  </div>
                )}
                <OtpInput
                  numInputs={4}
                  inputStyle={styles.otpInputStyle}
                  containerStyle={styles.otpContainerStyle}
                  separator={<span>&nbsp;</span>}
                  value={otp}
                  onChange={(otpNo) => setOtp(otpNo)}
                ></OtpInput>
                <label className={styles.expireLabel}>
                  OTP will expire in 5 minutes
                </label>
                <button
                  type="submit"
                  className={otp ? styles.btn : styles.disabledBtn}
                >
                  {!apiCallComplete && (
                    <div className={styles.loader}>
                      <img
                        alt="loader"
                        className={styles.loaderImg}
                        src={loader}
                      ></img>
                    </div>
                  )}
                  <div
                    className={
                      apiCallComplete
                        ? styles.buttonText
                        : styles.buttonTextLoading
                    }
                  >
                    {" "}
                    {apiCallComplete === true
                      ? t("login_button")
                      : t("loading")}
                  </div>
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
NewLogin.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(NewLogin);
