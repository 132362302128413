import React from "react";

import styles from "./ScrollNext.module.css";
import rightArrow from "../../assets/images/chevron-right.svg";

const ScrollNext = ({ ...props }) => {
  return (
    <div
      className={`${styles.scrollNextContainer} ${props.className}`}
      onClick={() => props.onClick()}
    >
      <img src={rightArrow} className={styles.chevronArrow} alt="arrow"></img>
    </div>
  );
};

export default ScrollNext;
