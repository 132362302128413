import React from "react";
import styles from "../Verification/Verification.module.css";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const Verification = ({ ...props }) => {
  const { t } = props;

  const [notes, setNotes] = useState("");

  const handleNotes = (note) => {
    setNotes(note.target.value);
    props.getNotes(note.target.value);
  };

  return (
    <div>
      <p className={styles.headingText}>Additional notes</p>
      <form>
        <div className={styles.phoneVerifyWrapper}></div>
        <textarea
          rows="4"
          value={notes}
          onChange={(n) => handleNotes(n)}
          placeholder={parse(
            t(
              "Send a note to your expert regarding your symptoms, allergies, etc..(optional)"
            )
          )}
        ></textarea>
      </form>
    </div>
  );
};

export default withTranslation()(Verification);
