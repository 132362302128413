import React from "react";
import { useEffect, useState } from "react";
import { getAvailableDoctors } from "../../../api/index";
import styles from "./Doctors.module.css";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import ScrollPrevious from "../../../ReusableComponents/ScrollPrevious/ScrollPrevious";
import ScrollNext from "../../../ReusableComponents/ScrollNext/ScrollNext";

const Doctors = ({ ...props }) => {
  const { t } = props;
  const [doctors, setDoctors] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();

  useEffect(() => {
    const fetchDoctors = async () => {
      if (props.doctorInfo) {
        const response = await getAvailableDoctors(props.service_id.id);
        findSequence(props.doctorInfo);
        setDoctors(response);
      }
    };
    fetchDoctors();
  }, [props.doctorInfo]);

  const findSequence = (docInfo) => {
    if (docInfo)
      for (var i = 0; i < docInfo.length; i++) {
        if (docInfo[i].resources.length === 1) {
          if (docInfo[i].resources[0].slots.length > 0) {
            var selectedDoctorId = docInfo[i].resources[0];
            props.getPrice(docInfo[i].resources[0].price);
            setSelectedDoctor(selectedDoctorId);
            props.selectedDoc(selectedDoctorId);
            break;
          } else {
            continue;
          }
        } else if (!docInfo[i].resources.length < 1) {
          const checkSlots = docInfo[i].resources.filter(
            (s) => s.slots.length > 0
          );
          if (checkSlots.length > 0) {
            docInfo[i].resources.sort((m, n) =>
              m.slots.length > 0 && n.slots.length > 0
                ? parseInt(m.slots[0].timestamp) -
                  parseInt(n.slots[0].timestamp)
                : m.slots.length > 0
                ? -1
                : 1
            );

            var selectedDoctorId = docInfo[i].resources[0];
            setSelectedDoctor(selectedDoctorId);
            props.getPrice(docInfo[i].resources[0].price);
            props.selectedDoc(selectedDoctorId);
            break;
          } else {
            continue;
          }
        } else {
          continue;
        }
      }
  };

  const selectDoctor = (doctor) => {
    setSelectedDoctor(doctor);
    //send back selected doctor
    props.selectedDoc(doctor);
  };

  useEffect(() => {
    if (selectedDoctor != null) {
      console.log(selectedDoctor.price);
      props.getPrice(selectedDoctor.price);
    }
  }, [selectedDoctor]);

  const getStyles = (doc) => {
    if (selectedDoctor.id === doc.id) {
      return true;
    } else return false;
  };

  const scrollNextWeek = () => {
    const e = document.getElementById("scroll-doc");
    const width = e.getBoundingClientRect().width;
    e.scrollLeft += width - 0;
  };

  const scrollPrevWeek = () => {
    const e = document.getElementById("scroll-doc");
    const width = e.getBoundingClientRect().width;
    e.scrollLeft -= width - 0;
  };

  function renderDoctors() {
    let docs = [];
    doctors.map((doc, index) => {
      let firstChar = doc.name.charAt(0);
      let description = doc.description ? doc.description.split("\\n") : "";
      docs.push(
        <div
          // className={styles.doctorInfoWrapper}
          className={`${
            styles.doctorInfoWrapper
          } doctorInfoWrapperCompanyStyles ${
            getStyles(doc)
              ? `${styles.selectedStyle} doctorSelectedCompanyStyles`
              : `${styles.deselectedStyle} doctorDeselectedStyleCompanyStyles`
          }`}
          onClick={() => selectDoctor(doc)}
        >
          <img
            className={`${styles.doctorImg} bookingDoctorImg`}
            src={
              doc.image == null
                ? `https://moodahead.s3.eu-central-1.amazonaws.com/moodahead-public/alphabetic-placeholders/${firstChar}.png`
                : doc.image
            }
            alt="No"
          ></img>
          <div className={styles.docNameWrapper}>
            <p className={`${styles.docName} bookingDoctorName`}>{doc.name}</p>
            <p className={`${styles.docExp} docExpCompanyStyles`}>
              {doc.description}
            </p>
            <p
              className={`${styles.docDescription} bookingDoctorDescriptionCompanyStyles`}
            >
              {/* {description.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))} */}
              {description}
            </p>
          </div>
        </div>
      );
    });

    return docs;
  }

  if (doctors && selectedDoctor && props.service_id) {
    return (
      <div className={styles.doctorsWrapper}>
        {/* <div className={styles.buttonWrapper}>
          <img src={leftArrow} alt="arrow" onClick={scrollPrevWeek}></img>
        </div> */}
        <ScrollPrevious
          className={styles.hide}
          onClick={scrollPrevWeek}
        ></ScrollPrevious>
        <div id="scroll-doc" className={styles.scrollableWrapper}>
          {doctors ? renderDoctors() : <p>{parse(t("Loading..."))}</p>}
        </div>
        {/* <div className={styles.buttonWrapper}>
          <img src={rightArrow} alt="arrow" onClick={scrollNextWeek}></img>
        </div> */}
        <ScrollNext
          className={styles.hide}
          onClick={scrollNextWeek}
        ></ScrollNext>
      </div>
    );
  } else return <p>No doctors available</p>;
};
export default withTranslation()(Doctors);
