import React, { useEffect } from "react";
import moment from "moment";
import styles from "./DashboardRedesign.module.css";
import noAppointments from "../../assets/images/no-appointments.svg";

export default function UpcomingAppointments({ ...props }) {
  const getDate = (timestamp) => {
    const day = moment(timestamp).calendar({
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[Last] dddd",
      nextWeek: "[Next] dddd",
      sameElse: "L",
    });

    const no_date_from = moment(timestamp).locale("en");
    return no_date_from.format("Do MMM yy ");
  };

  return (
    <>
      <div class={styles.table_section}>
        <span className={styles.bookedSessions}>Booked sessions</span>
        <div className={styles.appointmentTable}>
          <div className={styles.headingRow}>
            <span className={styles.scheduleWithHead}>Session with</span>
            <span className={styles.scheduleOnHead}>Date</span>
            <span className={styles.scheduleOnHeadResponsive}>Schedule on</span>
            <span className={styles.meetingLinkHead}>Time</span>
            <span className={styles.joinBtn}>Session link</span>
          </div>
          {props.upcomingAppointments &&
          props.upcomingAppointments.length > 0 ? (
            <div className={styles.tableDataWrapper}>
              {props.upcomingAppointments.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <span className={styles.scheduleWith}>
                    {item.resource_id.name}
                  </span>
                  <span className={styles.scheduleOn}>
                    {moment(item.booked_from).locale("en").format(" Do MMM yy")}
                    <br />
                    <span className={styles.responsiveDate}>
                      {moment(item.booked_from).format("hh:mm a")}
                    </span>
                  </span>
                  <span className={styles.meetingLink}>
                    {moment(item.booked_from).format("hh:mm a")}
                  </span>
                  <span className={`${styles.joinBtn} ${styles.btnCol}`}>
                    <a href={item.booking_url} target="_blank" rel="noreferrer">
                      <button class={styles.btnCol}>Join</button>
                    </a>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div class={styles.noData}>
              <img src={noAppointments} alt="noData" />
              <div class={styles.noData_msg}>
                <h2>No Session scheduled</h2>
                <p>
                  Book a professional appointment easily and coveniently online.
                  Talk to our curated professionals at your preferred time
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* // <div class={styles.table_section}>
        //   <div class={styles.tbl}>
        //     <table class={styles.table}>
        //       <thead>
        //         <tr>
        //           <th scope="col">Schedule with</th>
        //           <th scope="col">Schedule on</th>
        //           <th scope="col">Joining link</th>
        //           <th scope="col">Action</th>
        //         </tr>
        //       </thead>
        //       <tbody class={styles.table_group_divider}>
        //         <tr>
        //           <td colspan="6">
        //             <div class={styles.noData}>
        //               <img src={noAppointments} alt="noData" />
        //               <div class={styles.noData_msg}>
        //                 <h2>No Session scheduled</h2>
        //                 <p>
        //                   Book a professional appointment easily and coveniently
        //                   online. Talk to our curated professionals at your
        //                   preferred time
        //                 </p>
        //               </div>
        //             </div>
        //           </td>
        //         </tr>
        //       </tbody>
        //     </table>
        //   </div>
        // </div> */}
    </>
  );
}
