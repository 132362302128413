import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { withTranslation } from "react-i18next";
import logo from "../../assets/images/moodahead/moodahead_primary_logo.svg";
import whiteLogo from "../../assets/images/moodahead/moodahead_white_logo.svg";
import downArrow from "../../assets/images/moodahead/down_arrow.svg";
import avatar from "../../assets/images/moodahead/profile_avatar.svg";
import auth from "../Auth/auth";
import { useHistory } from "react-router";

const Navbar = ({ ...props }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const signOut = () => {
    auth.logout(() => {
      history.push("/login");
    });
  };

  const getUserName = () => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    return user.firstName + " " + user.lastName;
  };

  return (
    <div
      className={
        props.hasBackground
          ? styles.navbarWrapper
          : styles.navbarWrapperNoBackground
      }
    >
      <img
        onClick={() => history.push("/dashboard")}
        src={!props.hasBackground ? logo : whiteLogo}
        alt="fig"
        className={styles.navImage}
      ></img>

      <div onClick={() => setIsOpen(!isOpen)} className={styles.userWrapper}>
        <img className={styles.avatar} src={avatar} alt="user"></img>
        <img src={downArrow} alt="down_arrow"></img>
        <div
          style={{ display: isOpen === true ? "block" : "none" }}
          className={styles.dropdownWrapper}
        >
          <div
            onClick={() => history.push("/profile")}
            className={styles.dropdownOption}
          >
            <span className={styles.userName}>{getUserName()}</span>
            <span className={styles.manageProfile}>Manage profile</span>
          </div>
          <hr className={styles.dropdownHr}></hr>
          <div onClick={() => signOut()} className={styles.dropdownOption}>
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Navbar);
