import { useState } from "react";
import React, { useEffect } from "react";
import { fetchUpcomingAppointments, fetchServices } from "../../api/index";
import Header from "./Header";
import UpcomingAppointments from "./UpcomingAppointments";
import styles from "./DashboardRedesign.module.css";

export default function DashboardRedesign({ ...props }) {
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [isFirstAppointment, setIsFirstAppointment] = useState(false);

  const [service, setService] = useState();

  useEffect(() => {
    getAppointment();
    getService();
  }, []);

  const getAppointment = async () => {
    const response = await fetchUpcomingAppointments();

    setUpcomingAppointments(response.bookings);
    setIsFirstAppointment(!response.isOldBooking);
    // props.checkIsFirstAppointment(!response.isOldBooking);
  };

  const getService = async () => {
    const response = await fetchServices();
    console.log(response.data["services"][0]);
    setService(response.data["services"][0]);
  };

  return (
    <div className={styles.wrapper}>
      <Header
        service={service}
        isFirstAppointment={isFirstAppointment}
      ></Header>
      <UpcomingAppointments
        upcomingAppointments={upcomingAppointments}
      ></UpcomingAppointments>
      <footer>
        If you want to reschedule a session please drop a message at{" "}
        <span>+91 7719197777</span>
      </footer>
    </div>
  );
}
