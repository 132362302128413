import React, { useEffect } from "react";
import styles from "./Stepper.module.css";
import { useState } from "react";
import Doctors from "../Doctors/Doctors";
import AppointmentCalendar from "../Calendar/AppointmentCalendar";
import TimeSlots from "../TimeSlots/TimeSlots";
import tick from "../../../assets/images/tick.svg";
import close from "../../../assets/images/close.svg";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import Modal from "react-modal";
import { getAvailableDates } from "../../../api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { nb } from "date-fns/locale";

const Stepper = ({ ...props }) => {
  var today = new Date();
  const { t } = props;
  //store selected doctor, date and time slot
  const [currentDoc, setCurrentDoc] = useState("");
  const [currentDate, setCurrentDate] = useState();
  const [currentTime, setCurrentTime] = useState();

  const [selectedTime, setSelectedTime] = useState();
  const [fullDate, setFullDate] = useState();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackDate, setFeedbackDate] = useState();
  const [doctorInfo, setDoctorinfo] = useState();
  const [price, setPrice] = useState();

  useEffect(() => {
    let isMounted = true;
    let start = moment(today).add(0, "d").format("YYYY-MM-DD");
    let end = moment(today).add(6, "d").format("YYYY-MM-DD");
    let count = 0;
    const fetchData = async () => {
      const response = await getAvailableDates(props.service_id.id, start, end);
      let hasResources = false;
      for (var element in response) {
        if (response[element]["resources"].length > 0) {
          hasResources = true;
        }
      }
      if (hasResources === true) {
        if (isMounted) {
          setDoctorinfo(response);
          isMounted = false;
        }
      } else {
        if (count < 10) {
          start = moment(start).add(1, "d").format("YYYY-MM-DD");
          end = moment(start).add(7, "d").format("YYYY-MM-DD");
          fetchData();
          count++;
        }
      }
    };
    fetchData();
  }, []);

  //When doctor changes change dates and time slots
  const onChangeDoctor = (value) => {
    setCurrentDoc();
    setCurrentDate();
    setCurrentTime();
    setSelectedTime();
    setCurrentDoc(value);
  };

  //When date changes change time slots
  const onChangeDate = (value) => {
    setCurrentDate();
    setCurrentTime();
    if (value) {
      const a = convertDate(value);
      setCurrentDate(a);
    }

    setFullDate(value);
  };

  const onChangeTime = (value) => {
    setCurrentTime();
    setCurrentTime(value);
  };

  const convertDate = (f) => {
    let d = ("0" + f.getDate()).slice(-2);
    let m = ("0" + (f.getMonth() + 1)).slice(-2);
    let y = f.getFullYear();

    let fullDate = d + "-" + m + "-" + y;
    return fullDate;
  };

  const timeSlotSelected = (value) => {
    setSelectedTime(value);
  };

  useEffect(() => {
    //Send back details

    props.finalDoc(currentDoc, currentDate, selectedTime);
  }, [currentDoc, currentDate, selectedTime, props]);

  const toggleFeedbackModal = () => {
    if (showFeedbackModal === true) {
      setShowFeedbackModal(false);
    } else {
      setShowFeedbackModal(true);
    }
  };

  const collectFeedback = () => {
    if (feedbackDate) {
      setShowFeedbackModal(false);
    }
  };

  const getPrice = (value) => {
    setPrice(value);
  };

  return (
    <>
      <Modal
        isOpen={showFeedbackModal}
        shouldCloseOnEsc={true}
        onRequestClose={() => toggleFeedbackModal()}
        shouldCloseOnOverlayClick={false}
        className={styles.modalStyle}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContainer}>
          <div
            className={styles.modalPart1Subpart2}
            onClick={() => toggleFeedbackModal()}
          >
            <img src={close}></img>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.modalpart1}>
              <div className={styles.modalHeadingWrapper}>
                <p className={styles.heading}>
                  {parse(t("We'll make it happen!"))}
                </p>
                <p className={styles.subHeading}>
                  {parse(
                    t(
                      "Please leave your preferred time/date and you will receive a notification and a SMS to confirm under 48 hrs"
                    )
                  )}
                </p>
              </div>
            </div>
            <div className={styles.formDiv}>
              <div className={styles.inputDiv}>
                <p>{parse(t("Date & Time"))}</p>
                {/* <input type="datetime-local" id="date1" min={setMinDate()} placeholder='DD/MM/YYYY' value={feedbackDate} onChange={(date) => setFeedbackDate(date.target.value)}></input> */}
                <DatePicker
                  timeCaption={parse(t("Time"))}
                  locale={nb}
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={moment(today).add(1, "d")._d}
                  required={true}
                  placeholderText={moment(today)
                    .add(7, "d")
                    .format("MMMM DD, yyyy h:mm a")}
                  selected={feedbackDate}
                  showTimeSelect
                  onChange={(date) => setFeedbackDate(date)}
                ></DatePicker>
              </div>
            </div>
            <button
              onClick={() => collectFeedback()}
              className={styles.formButton}
            >
              {parse(t("Submit"))}
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.bookingStepWrapper}>
        <div className={styles.selectDateHeadingWrapper}>
          <div className={styles.bookingStepHeadingWrapper}>
            <img
              src={tick}
              alt="tick"
              className={"stepperTickCompanyStyles"}
              style={{ display: currentDoc ? "block" : "none" }}
            ></img>
            <p
              className={`${styles.bookingStepHeadingText} bookingStepHeadingTextCompanyStyles`}
              style={{ color: currentDoc ? "#147C65" : "black" }}
            >
              Select a Professional
            </p>
          </div>
          <p
            className={styles.price}
            style={{
              textDecoration: props.isFree ? "line-through" : "none",
            }}
          >
            {price} / Session
          </p>
        </div>
        <Doctors
          doctorInfo={doctorInfo}
          service_id={props.service_id}
          selectedDoc={onChangeDoctor}
          getPrice={getPrice}
        ></Doctors>
      </div>
      <hr className={styles.hrLine}></hr>
      <div className={` ${styles.bookingStepWrapper} ${styles.step2}`}>
        <div className={styles.selectDateHeadingWrapper}>
          <div className={styles.bookingStepHeadingWrapper}>
            <img
              src={tick}
              alt="tick"
              className={"stepperTickCompanyStyles"}
              style={{ display: currentDate ? "block" : "none" }}
            ></img>
            <p
              className={`${styles.bookingStepHeadingText} bookingStepHeadingTextCompanyStyles`}
              style={{ color: currentDate ? "#147C65" : "black" }}
            >
              {parse(t("Select a Date"))}
            </p>
          </div>
          {/* <p
            onClick={() => toggleFeedbackModal()}
            className={`${styles.slotHeader} slotHeaderCompnayStyles`}
          >
            {parse(t("Can’t find a slot"))}
          </p> */}
        </div>
        <AppointmentCalendar
          doctorInfo={doctorInfo}
          service_id={props.service_id}
          selectedDoc={currentDoc}
          selectedDate={onChangeDate}
          timeSlots={onChangeTime}
        ></AppointmentCalendar>
      </div>
      <hr className={styles.hrLine}></hr>
      <div className={`${styles.bookingStepWrapper} ${styles.step3} `}>
        <div className={styles.selectDateHeadingWrapper}>
          <div className={styles.bookingStepHeadingWrapper}>
            <img
              src={tick}
              alt="tick"
              className={"stepperTickCompanyStyles"}
              style={{ display: selectedTime ? "block" : "none" }}
            ></img>
            <p
              className={`${styles.bookingStepHeadingText} bookingStepHeadingTextCompanyStyles`}
              style={{ color: selectedTime ? "#147C65" : "black" }}
            >
              {parse(t("Select a Slot"))}
            </p>
          </div>
          {/* <p
            onClick={() => toggleFeedbackModal()}
            className={`${styles.slotHeader} slotHeaderCompnayStyles`}
          >
            {parse(t("Can’t find a slot"))}
          </p> */}
        </div>
        <TimeSlots
          times={currentTime}
          selectedTime={timeSlotSelected}
          dateSelected={fullDate}
        ></TimeSlots>
      </div>
    </>
  );
};

export default withTranslation()(Stepper);
