import Navbar from "../Navbar/Navbar";
import styles from "../Profile/Profile.module.css";
import BFman1 from "../../assets/images/moodahead/yellow_mood.svg";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { getProfile, updateProfile } from "../../api";
import { useToasts } from "react-toast-notifications";

export default function Profile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [phonenumber, setPhone] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    const getUserProfile = async () => {
      const response = await getProfile();
      setFirstName(response.user.firstName);
      setLastName(response.user.lastName);
      setEmail(response.user.emailId);
      setPhone(response.user.phoneNumber);
      setGender(response.user.gender);
    };
    getUserProfile();
  }, []);

  const getGenderStyles = (gen) => {
    if (!isEditable && gen === gender) {
      return styles.selectedDisabledGender;
    } else if (!isEditable && gen !== gender) {
      return styles.gender;
    } else if (isEditable && gen === gender) {
      return styles.activeGender;
    } else {
      return styles.gender;
    }
  };

  const handleButtonClick = async () => {
    if (isEditable) {
      if (firstName.replace(/\s/g, "").length > 0 && firstName.length > 0) {
        if (lastName.replace(/\s/g, "").length > 0 && lastName.length > 0) {
          await updateProfile(firstName, lastName, email, gender, phonenumber)
            .then(() => {
              const userString = localStorage.getItem("user");
              const user = JSON.parse(userString);
              user.firstName = firstName;
              user.lastName = lastName;
              localStorage.setItem("user", JSON.stringify(user));
              addToast("Profile saved successfully", {
                appearance: "success",
                autoDismiss: true,
              });
            })
            .catch((err) => {
              addToast("Something went wrong", {
                appearance: "error",
                autoDismiss: true,
              });
            });
          setIsEditable(false);
        } else {
          addToast("Last name cannot be empty", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        addToast("First name cannot be empty", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      setIsEditable(true);
    }
  };

  return (
    <>
      <Navbar></Navbar>
      <div className={styles.mainContainer}>
        <div className={styles.headingWrapper}>
          <img src={BFman1} alt="doctor" className={styles.moodImg}></img>
          <div className={styles.headingContentWrapper}>
            <span className={styles.heading}>Your Profile</span>
            <span className={styles.subHeading}>Manage your details here</span>
          </div>
        </div>
        <hr className={styles.headingHr}></hr>
        <div className={styles.detailRow}>
          <div className={styles.detailColumn}>
            <label className={styles.detailLabel}>First name</label>
            <input
              className={
                isEditable ? styles.profileInput : styles.profileInputDisabled
              }
              type="text"
              value={firstName}
              disabled={!isEditable}
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
          </div>
          <div className={styles.detailColumn}>
            <label className={styles.detailLabel}>Last name</label>
            <input
              className={
                isEditable ? styles.profileInput : styles.profileInputDisabled
              }
              type="text"
              value={lastName}
              disabled={!isEditable}
              onChange={(e) => setLastName(e.target.value)}
            ></input>
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.detailColumn}>
            <label className={styles.detailLabel}>Email</label>
            <input
              className={
                isEditable ? styles.profileInput : styles.profileInputDisabled
              }
              type="text"
              value={email}
              disabled={true}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className={styles.detailColumnPhoneDisabled}>
            <label className={styles.detailLabel}>Phone number</label>
            <PhoneInput
              placeholder="+910000000000"
              value={phonenumber}
              onChange={(e) => setPhone(e)}
              country={"in"}
              disabled={true}
              className={styles.profileInput}
            ></PhoneInput>
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.detailColumn}>
            <label className={styles.detailLabel}>Gender</label>
            <div className={styles.genderWrapper}>
              <div
                onClick={() => {
                  if (isEditable) {
                    setGender("male");
                  }
                }}
                className={getGenderStyles("male")}
              >
                Male
              </div>
              <div
                onClick={() => {
                  if (isEditable) {
                    setGender("female");
                  }
                }}
                className={getGenderStyles("female")}
              >
                Female
              </div>
              <div
                onClick={() => {
                  if (isEditable) {
                    setGender("other");
                  }
                }}
                className={getGenderStyles("other")}
              >
                Other
              </div>
            </div>
          </div>
        </div>
        <div className={styles.detailRow}>
          <button
            onClick={() => handleButtonClick()}
            className={styles.profileButton}
          >
            {isEditable ? "Save Details" : "Edit Details"}
          </button>
        </div>
      </div>
    </>
  );
}
