import React from "react";
import styles from "./SideBox.module.css";
import BFman1 from "../../../assets/images/step_mood.svg";
// import { parse } from 'dotenv/types';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import Stepper from "@keyvaluesystems/react-stepper";

const SideBox = ({ ...props }) => {
  const { t } = props;

  const initialStepsArr = [
    {
      stepLabel: "Choose Your Guide",
      completed: false,
    },
    {
      stepLabel: "Pick a date and time that fits your groove",
      completed: false,
    },
    {
      stepLabel: "Click & Connect",
      completed: false,
    },
  ];

  const stepperStyles = {
    LineSeparator: () => ({
      backgroundColor: "#FFAC57",
    }),
    InactiveLineSeparator: () => ({
      backgroundColor: "#FFAC57",
    }),
    ActiveNode: () => ({
      backgroundColor: "#FFAC57",
    }),

    CompletedNode: () => ({
      backgroundColor: "#FFAC57",
    }),
    InActiveNode: () => ({
      backgroundColor: "#FFAC57",
      opacity: 1,
      color: "#000",
      fontFamily: "Satoshi",
      fontWeight: 500,
      fontSize: "14px",
    }),
  };

  return (
    <div className={styles.contentWrapper}>
      <div className={` ${styles.sideboxWrapper} sideboxWrapperCompanyStyles`}>
        <p className={`${styles.header} sideboxHeaderCompanyStyles`}>
          {parse(t("Your Guide to Booking a Moodahead Session"))}
        </p>
        {/* <div className={` ${styles.stepsWrapper} stepsWrapperCompanyStyles`}>
          <div className={styles.step}>
            <p className={`${styles.stepNumber} stepNumberCompanyStyles`}>1</p>
            <p
              className={`${styles.stepDescription} stepDescriptionCompanyStyles`}
            >
              {parse(t("Choose Your Guide"))}
            </p>
          </div>
          <div className={styles.step}>
            <p className={`${styles.stepNumber} stepNumberCompanyStyles`}>2</p>
            <p
              className={`${styles.stepDescription} stepDescriptionCompanyStyles`}
            >
              {parse(t("Pick a date and time that fits your groove"))}
            </p>
          </div>
          <div className={styles.step}>
            <p className={`${styles.stepNumber} stepNumberCompanyStyles`}>3</p>
            <p
              className={`${styles.stepDescription} stepDescriptionCompanyStyles`}
            >
               {parse(t("Meet Doctors Online"))} 
              Click & Connect
            </p>
          </div>
        </div> */}
        <div className={styles.stepperNav}>
          <Stepper
            steps={initialStepsArr}
            currentStepIndex={-2}
            orientation="vertical"
            labelPosition="right"
            styles={stepperStyles}

            // onStepClick={handleStepClick}
            // stepContent={(step) => (
            //   <div
            //     style={{
            //       height: "200px",
            //       width: "100%",
            //       margin: "10px",
            //       border: "1px solid black",
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //     }}
            //   >
            //     {step.stepLabel}
            //   </div>
            // )}
          />
        </div>
        <img
          src={BFman1}
          alt="doctor"
          className={`${styles.docImg} sideboxImgCompanyStyles`}
        ></img>
      </div>
    </div>
  );
};

export default withTranslation()(SideBox);
